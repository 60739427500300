
.v-input__icon {
    display: none;
}
.v-menu {
    display: none;
}
.titleColor {
    color: #4a9945;
}
.infoVin {
    margin-top: 15px;
    margin-bottom: 15px;
}
.subtitle {
    margin-top: 10px;
    font-size: 15px;
}
.centerHorizontalVertical {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    /* Vertical alignment */
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    /* Horizontal alignment */
    height: 100%;
}
